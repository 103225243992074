import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { HiXMark } from "react-icons/hi2";
import { portfolio_assets } from "../utils/data";
import Aos from "aos";

function WebDevPortfolio() {
  const [showModel, setShowModel] = useState(false);
  const [index, setIndex] = useState(0);
  const { id } = useParams();
  const [item] = useState(portfolio_assets.filter((item) => item.id == id)[0]);
  const incrementIndex = () => {
    if (index < item.listImages.length - 1) {
      setIndex((prev) => prev + 1);
    }
  };
  const deccrementIndex = () => {
    if (index > 0 && index <= item.listImages.length - 1) {
      setIndex((prev) => prev - 1);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className=" pt-[100px] mb-20">
      <div
        style={{
          backgroundImage: `url(${item.bg})`,
        }}
        className=" w-full h-[400px] flex items-center   z-0 relative  justify-center  bg-no-repeat bg-cover bg-center"
      >
        <div className=" absolute z-10 bg-[#222222ed] w-full h-full"></div>
        <div className="  z-20 text-center">
          <h1 className=" text-[20px] md:text-[26px] lg:text-[40px] font-semibold text-customGold">
            {item.name}
          </h1>
          <h3 className=" text-[15px] md:text-[17px] lg:text-[20px] font-medium underline cursor-pointer text-fuchsia-100">
            {item.category}
          </h3>
        </div>
      </div>
      <div className=" flex-col lg:flex-row flex gap-8 px-4 sm:px-10 md:px-16 xl:px-36">
        {/* Slider Image Model */}
        {showModel ? (
          <div className=" bg-black/80 z-40  fixed left-0 top-0 h-full w-full flex  justify-center items-center ">
            <span
              className=" absolute  text-white text-[30px] top-5 right-10 cursor-pointer hover:text-red-500 transition-all duration-300 "
              onClick={() => setShowModel(false)}
            >
              <HiXMark />
            </span>
            <img
              src={item.listImages[index]}
              className=" m-auto w-[90%] h-[84%] object-contain "
              alt=""
            />
            <div className=" absolute left-0 w-full cursor-pointer pointer-events-auto px-4 md:px-10 lg:px-16 text-[24px] md:text-[30px] text-white flex justify-between items-center">
              <span>
                <FaChevronLeft
                  className={`text-customBlue duration-300 transition-all ${
                    index === 0 ? " cursor-no-drop text-gray-400" : ""
                  }`}
                  onClick={() => deccrementIndex()}
                />
              </span>
              <span>
                <FaChevronRight
                  className={`text-customBlue duration-300 transition-all ${
                    index === item.listImages.length - 1
                      ? "  cursor-no-drop text-gray-400"
                      : ""
                  }`}
                  onClick={() => incrementIndex()}
                />
              </span>
            </div>
          </div>
        ) : null}
        <div className=" w-full lg:w-[50%] pt-32">
          <p className=" text-white text-[14px] md:text-[15px] lg:text-[16px]">
            {item.description}
          </p>
          <h1 className=" pt-10 pb-6 text-customBlue">
            Caractéristiques du site web :
          </h1>
          <ul className=" flex flex-col gap-3 text-white pl-2 md:pl-4 lg:pl-10 mb-6">
            {item.features.map((text, index) => (
              <li data-aos="fade-right">
                <span className=" text-customGold">❑&#8287;</span> {text}
              </li>
            ))}
            {/* <li data-aos="fade-right">
          <span className=" text-customGold">❑&#8287;</span> Site Static
        </li>
        <li data-aos="fade-right">
          <span className=" text-customGold">❑&#8287;</span> Design
          adaptatif (responsive design)
        </li>
        <li data-aos="fade-right">
          <span className=" text-customGold">❑&#8287;</span> Interfaces
          modernes
        </li>
        <li data-aos="fade-right">
          <span className=" text-customGold">❑&#8287;</span> Interactions
          fluides
        </li>
        <li data-aos="fade-right">
          <span className=" text-customGold">❑&#8287;</span> Expérience
          utilisateur optimale
        </li>
        <li data-aos="fade-right">
          <span className=" text-customGold">❑&#8287;</span> Accessibilité
          optimale
        </li> */}
          </ul>
          <div className=" grid grid-cols-3 gap-5">
            {item.listImages.map((image, index) => (
              <div
                onClick={() => {
                  setShowModel(true);
                  setIndex(index);
                }}
                key={index}
                className=" group relative  cursor-pointer h-fit"
              >
                <div className=" hidden group-hover:block transition-all duration-200 absolute h-full w-full bg-black/45 z-10"></div>
                <img data-aos="zoom-in-up" src={image} alt="" />
              </div>
            ))}
          </div>
          <div>
            <h1 className=" py-8 text-white font-semibold lg:text-[17px]">
              Vous pouvez consulter le site à travers le lien suivant :
            </h1>
            <a
              className=" text-customBlue underline"
              target="_blank"
              rel="noreferrer"
              href={item.website}
            >
              {item.website}
            </a>
          </div>
        </div>
        <img
          src={item.home}
          alt=""
          className=" rounded-lg z-30 lg:-mt-[120px] w-full lg:w-[50%]"
        />
      </div>
    </div>
  );
}

export default WebDevPortfolio;
