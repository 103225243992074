import React from "react";

function HeaderPorfolio() {
  return (
    <div className=" w-full h-[200px] mt-20 flex items-center   z-0 relative  justify-center  bg-no-repeat bg-cover bg-center">
      <div className=" absolute z-10 bg-gradient-to-r from-black to-gray-900 w-full h-full"></div>
      <div className="  z-20 text-center">
        <h1 className="text-[20px] md:text-[26px] lg:text-[40px] font-semibold text-customGold">
          Nos Références
        </h1>
      </div>
    </div>
  );
}

export default HeaderPorfolio;
