import React, { useEffect } from "react";
import Lottie from "lottie-react";
import Button from "../components/Button";
// import bg from "../assets/bg.json";
import bg from "../assets/bgLottie.json";
import Title from "../components/Title";
import { services } from "../utils/data";
import Aos from "aos";
import TestCarousel from "../components/TestCarousel";
import PartnersComponent from "../components/PartnersComponent";
import Testimonials from "../components/Testimonials";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <article className="z-20 ">
      {/* <div className="h-screen px-4 sm:px-10 md:px-16 xl:px-36 flex items-center gap-4 mt-6  pt-[105px] flex-col lg:flex-row ">
        <div className=" text-white flex-1 flex flex-col gap-3">
          <div data-aos="fade-right">
            <h1 className=" text-[30px] md:text-[45px] lg:text-[80px] text-customGold font-bold">
              EVOMARKET
            </h1>
            <h2 className=" text-customBlue text-[20px] md:text-[30px] -mt-[10px]">
              STUDIO DE CRÉATION
            </h2>
          </div>
          <p className=" leading-[27px] text-[15px] md:text-[17px]">
            Une{" "}
            <span className=" text-customGold font-semibold">
              agence de communication globale
            </span>{" "}
            qui vous accompagne dans le développement de votre image de marque.
          </p>
          <p className=" text-[15px] mb-5 md:text-[17px]">
            Notre agence œuvre à{" "}
            <span className=" text-customBlue font-bold">
              trouver des solutions de communication adéquates
            </span>{" "}
            <br />à chaque entreprise et prend en charge la réalisation de{" "}
            <span className=" text-customBlue font-bold">
              tous les supports qui l'accompagnent.
            </span>
          </p>
          <Button lien="/a-propos" title="En savoir plus" />
        </div>
        <div className=" w-[100%] sm:w-[80%] md:w-[50%] lg:w-[50%]">
          <Lottie animationData={bg} loop={false} className=" bg-no-repeat" />
        </div>
      </div> */}
      <div className="h-[900px] md:h-screen lg:h-screen px-4 sm:px-10 md:px-16 xl:px-36 flex items-center gap-8 lg:gap-16 mt-6 pt-[105px] flex-col lg:flex-row bg-gradient-to-r from-black to-gray-900 text-white">
        <div className="flex-1 flex flex-col gap-6" data-aos="fade-right">
          <h1 className="text-[35px] md:text-[50px] lg:text-[85px] text-customGold font-extrabold tracking-tight">
            EVOMARKET
          </h1>
          <h2 className="text-customBlue text-[22px] md:text-[32px] -mt-[12px] font-medium">
            AGENCE DE MARKETING DIGITAL
          </h2>
          <p className="leading-relaxed text-[14px] md:text-[18px]">
            Nous sommes une{" "}
            <span className="text-customGold font-semibold">
              agence de communication globale
            </span>{" "}
            qui vous aide à construire et à améliorer votre image de marque.
          </p>
          <p className="text-[14px] md:text-[18px]">
            Notre agence se spécialise dans{" "}
            <span className="text-customBlue font-bold">
              la recherche de solutions de communication efficaces
            </span>{" "}
            pour les entreprises, en prenant en charge la création de{" "}
            <span className="text-customBlue font-bold">
              tous les supports marketing
            </span>{" "}
            qui soutiennent leur croissance.
          </p>
          <Button
            lien="/a-propos"
            title="En savoir plus"
            className="bg-customBlue hover:bg-blue-700 px-6 py-3 rounded-lg transition-all duration-300"
          />
        </div>

        <div
          className="w-full sm:w-[80%] md:w-[50%] lg:w-[45%] "
          data-aos="fade-left"
        >
          <Lottie animationData={bg} loop={true} className="bg-no-repeat" />
        </div>
      </div>

      {/*Start Services Section */}
      <section className=" py-20 px-4 sm:px-10 md:px-16 xl:px-36">
        <div className=" flex items-start gap-8 flex-col xl:flex-row">
          <Title
            title="NOS SERVICES"
            textButton="Découvrez nos services"
            link="services"
            desc="Chez Evomarket, nous nous sommes engagés à transformer vos idées et vos ambitions en de véritables histoires de succès, c’est pour cela qu’on vous propose une gamme complète de services de communication et de marketing…"
          />
          <div className=" flex items-center sm:grid sm:grid-cols-2 lg:grid-cols-3  gap-4 flex-col">
            {services.map((serv) => (
              <div
                data-aos="zoom-in-up"
                key={serv.id}
                className=" flex flex-col gap-2 text-white justify-center items-center"
              >
                <img
                  className=" w-[100px] h-[100px] rounded-full"
                  src={serv.image}
                  alt={serv.name}
                />
                <a
                  href={serv.lien}
                  className="underline text-customGold font-medium text-[16px]"
                >
                  {serv.name}
                </a>
                <p className=" text-center h-[95px]">{serv.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*End Services Section */}
      <section>
        <PartnersComponent />
      </section>
      {/* Start Projects Section */}
      <section className=" py-20 px-4 sm:px-10 md:px-16 xl:px-36">
        <div className=" flex items-start gap-8 flex-col xl:flex-row overflow-hidden">
          <Title
            title="NOS Réalisations"
            textButton="Découvrir nos réalisations"
            link="portfolio"
            desc="Découvrez notre passion et notre engagement envers le succès des projets entrepris à travers notre portfolio. Chaque réalisation représente le fruit d’une étroite collaboration entre nos collaborateurs et nos clients…"
          />
          <TestCarousel />
        </div>
      </section>
      {/* End Projects Section*/}
      <div className="py-20 px-4 sm:px-10 md:px-16 xl:px-36">
        <Testimonials />
      </div>
    </article>
  );
};

export default Home;
