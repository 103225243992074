import React, { useEffect } from "react";
import sendMail from "../assets/sendMail.png";
import { BsSendFill } from "react-icons/bs";
import logo from "../assets/EVO.png";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { RiMapPin2Fill } from "react-icons/ri";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaFax } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import Aos from "aos";
const Footer = () => {
const location = useLocation();

  useEffect(() => {
    Aos.init({
      duration: 1100,
    });
  }, []);
  return (
    <footer>
      <div className=" px-4 sm:px-10 lg:px-20  text-white">
        {/* Start contact  */}
        <div className=" bg-customDark/15 lg:px-20 2xl:px-40 lg:gap-10  lg:flex-row py-3 px-4 flex-col flex items-center justify-between">
          <form className=" flex flex-col gap-5">
            <h1
              data-aos="fade-right"
              className={` w-fit before:content-[''] before:absolute before:z-[-1] z-10 before:top-5  relative before:w-[60%] before:h-2 before:bg-customBlue  text-customGold uppercase text-[20px] md:text-[22px] lg:text-[26px] font-bold`}
            >
              Restez branchés
            </h1>
            <p className="">
              Abonnez-vous à notre newsletter mensuelle afin de ne rien rater
              des nouvelles de l’agence et des domaines de la communication et
              du marketing
            </p>
            <div className=" flex items-center flex-col gap-4 sm:gap-0 sm:flex-row text-[13px] lg:text-[15px]">
              <input
                type="email"
                required
                className=" outline-none rounded-tl-sm rounded-bl-sm  text-customBlue py-2 px-6 border w-full  lg:w-[400px] border-customBlue "
                placeholder="Votre Adresse Email"
              />
              <button
                type="submit"
                className=" w-full md:w-fit justify-center flex items-center gap-3  py-2 px-2 hover:text-customBlue border border-transparent hover:border-customBlue hover:bg-transparent sm:border-l-0 transition-all duration-300 bg-customBlue text-white "
              >
                <BsSendFill /> S'abonner
              </button>
            </div>
          </form>
          <div className=" relative w-[290px] lg:w-[400px] h-[260px]">
            <img src={sendMail} alt="" className=" absolute w-full h-full" />
          </div>
        </div>
        {/* End Contact */}
        <div className=" flex justify-between flex-col lg:flex-row mt-10 pt-10  gap-8">
          <div className=" flex flex-col gap-6">
            <img
              src={logo}
              alt=""
              className=" w-[80px] h-[65px] md:w-[90px] md:h-[80px] lg:w-[100px] lg:h-[80px] object-contain"
            />
            <ul className=" flex gap-4 flex-col text-[14px] justify-between h-full">
              <li className=" flex items-center gap-2">
                {" "}
                <RiMapPin2Fill className=" text-customGold" />
                10 Rue Ghandi, Technopark, Office R 401, Rabat
              </li>
              <li className=" flex items-center gap-2">
                <MdEmail className=" text-customGold" />{" "}
                contact@evomarketagency.com
              </li>
              <li className=" flex items-center gap-2">
                {" "}
                <FaFax className=" text-customGold" />
                +212 5 30 31 50 11
              </li>

              <li className=" flex items-center gap-2">
                <IoCall className=" text-customGold" /> +212 6 24 45 88 47
              </li>
            </ul>
          </div>
          <ul className=" flex flex-col gap-6">
            <li className=" text-[22px]  font-bold">Accès rapide</li>
            <li className={`w-fit transition-all duration-300 hover:text-customBlue lg:text-[15px] relative after:w-0 after:transition-all after:duration-300 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0 ${location.pathname === "/" ? 'after:w-full text-customBlue' : '  '}`}>
              <a href="/">Accueil</a>
            </li>
            <li className={`w-fit transition-all duration-300 hover:text-customBlue lg:text-[15px] relative after:w-0 after:transition-all after:duration-300 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0 ${location.pathname === "/a-propos" ? 'after:w-full text-customBlue' : '  '}`}>
              <a href="/a-propos">À Propos</a>
            </li>
            <li className="w-fit transition-all duration-300 hover:text-customBlue lg:text-[15px] relative after:w-0 after:transition-all after:duration-300 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0">
              <a href="/services">Nos Services</a>
            </li>
            <li className="w-fit transition-all duration-300 hover:text-customBlue lg:text-[15px] relative after:w-0 after:transition-all after:duration-300 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0">
              <a href="/portfolio">Portfolio</a>
            </li>
            {/* <li className="w-fit transition-all duration-300 hover:text-customBlue lg:text-[15px] relative after:w-0 after:transition-all after:duration-300 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0">
              <a href="/">Blog</a>
            </li> */}
          </ul>
          <div className=" flex flex-col justify-between">
            <div className=" flex flex-col gap-3">
              <h1 className=" text-customBlue text-[22px] leading-[22px]">
                <span className=" text-customGold font-semibold text-[26px] uppercase">
                  Evomarket
                </span>{" "}
                <br /> Studio de Création
              </h1>
              <p className=" text-[15px] lg:w-[400px]">
                Une agence de communication globale qui vous accompagne dans le
                développement de votre image de marque.
              </p>
            </div>
            <ul className=" flex gap-3 flex-col">
              <li className=" text-[18px]">Rejoignez nos réseaux</li>
              <li className=" flex gap-3 items-center text-[22px]">
                {/* <a
                  href="/"
                  className=" hover:text-customGold duration-300 transition-all"
                  target="_blank" rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a> */}
                <a
                  href="https://www.instagram.com/evomarket.agency/?utm_source=ig_web_button_share_sheet"
                  className=" hover:text-customGold duration-300 transition-all"
                  target="_blank" rel="noopener noreferrer"
                >
                  <FaInstagramSquare />
                </a>
                <a
                  href="https://www.linkedin.com/company/evomarket"
                  className=" hover:text-customGold duration-300 transition-all"
                  target="_blank" rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className=" bg-customBlue py-3 flex justify-center items-center text-white mt-10">
        <p className=" text-[13px] lg:text-[17px] text-center">
          ©2024 Evomarket Studio de Création | Tous Droits Réservés
        </p>
      </div>
    </footer>
  );
};

export default Footer;
