import web from "../assets/services/web.jpg";
import marketing from "../assets/services/marketing.jpg";
import video from "../assets/services/video.jpg";
import opic from "../assets/Partners/opic.png";
import mpm from "../assets/Partners/mpm.png";
import audis from "../assets/Partners/audis.png";
import chronovision from "../assets/Partners/chronovision.png";
import essaber from "../assets/Partners/essaber.png";
import electrosky from "../assets/Partners/electrosky.png";
import conquete from "../assets/Partners/conquete.png";
import esgt from "../assets/Partners/esgt.png";
import opicProject from "../assets/projects/opic.png";
import mpmProject from "../assets/projects/MPM.png";

// images services
// import reseaux_sociaux from "../assets/services/reseaux sociaux.jpg";
import reseaux_sociaux from "../assets/services/3-services.webp";
import stratégie from "../assets/services/1-services-2.webp";
import webDev from "../assets/services/2-services-2.webp";
import events from "../assets/services/4-services.webp";

import portfolio_opic from "../assets/projects/opicHomePage.png";
import portfolio_mpm from '../assets/projects/MPM.png';
// OPIC Instagram
import sm_opic_1 from '../assets/portfolio/socialmedia/opic/1.png';
import sm_opic_2 from '../assets/portfolio/socialmedia/opic/2.png';
import sm_opic_3 from '../assets/portfolio/socialmedia/opic/3.png';
import sm_opic_4 from '../assets/portfolio/socialmedia/opic/6.png';
import sm_opic_5 from '../assets/portfolio/socialmedia/opic/7.png';
import sm_opic_6 from '../assets/portfolio/socialmedia/opic/8.png';
import sm_opic_7 from '../assets/portfolio/socialmedia/opic/19.png';
import sm_opic_8 from '../assets/portfolio/socialmedia/opic/27.png';
import sm_opic_9 from '../assets/portfolio/socialmedia/opic/38.png';

// Audis Instagram
import sm_audis_1 from '../assets/portfolio/socialmedia/audis/1.png';
import sm_audis_2 from '../assets/portfolio/socialmedia/audis/3.png';
import sm_audis_3 from '../assets/portfolio/socialmedia/audis/7.png';
import sm_audis_4 from '../assets/portfolio/socialmedia/audis/10.png';
import sm_audis_5 from '../assets/portfolio/socialmedia/audis/16.png';
import sm_audis_6 from '../assets/portfolio/socialmedia/audis/17.png';
import sm_audis_7 from '../assets/portfolio/socialmedia/audis/18.png';
import sm_audis_8 from '../assets/portfolio/socialmedia/audis/19.png';
import sm_audis_9 from '../assets/portfolio/socialmedia/audis/20.png';
import sm_audis_10 from '../assets/portfolio/socialmedia/audis/22.png';
import sm_audis_11 from '../assets/portfolio/socialmedia/audis/23.png';
import sm_audis_12 from '../assets/portfolio/socialmedia/audis/24.png';


// Chronovision  instagram  
import sm_chronocasa_1 from '../assets/portfolio/socialmedia/chronocasa/33.jpg';
import sm_chronocasa_2 from '../assets/portfolio/socialmedia/chronocasa/34.jpg';
import sm_chronocasa_3 from '../assets/portfolio/socialmedia/chronocasa/44.jpg';
import sm_chronocasa_4 from '../assets/portfolio/socialmedia/chronocasa/45.jpg';
import sm_chronocasa_5 from '../assets/portfolio/socialmedia/chronocasa/46.jpg';
import sm_chronocasa_6 from '../assets/portfolio/socialmedia/chronocasa/54.jpg';
import sm_chronocasa_7 from '../assets/portfolio/socialmedia/chronocasa/55.jpg';
import sm_chronocasa_8 from '../assets/portfolio/socialmedia/chronocasa/56.jpg';
import sm_chronocasa_9 from '../assets/portfolio/socialmedia/chronocasa/57.jpg';


// MPM 
import sm_mpm_1 from '../assets/portfolio/socialmedia/mpm/1.png';
import sm_mpm_2 from '../assets/portfolio/socialmedia/mpm/2.png';
import sm_mpm_3 from '../assets/portfolio/socialmedia/mpm/3.png';
import sm_mpm_4 from '../assets/portfolio/socialmedia/mpm/4.png';
import sm_mpm_5 from '../assets/portfolio/socialmedia/mpm/5.png';
import sm_mpm_6 from '../assets/portfolio/socialmedia/mpm/6.png';
import sm_mpm_7 from '../assets/portfolio/socialmedia/mpm/7.png';
import sm_mpm_8 from '../assets/portfolio/socialmedia/mpm/8.png';
import sm_mpm_9 from '../assets/portfolio/socialmedia/mpm/9.png';
import sm_mpm_10 from '../assets/portfolio/socialmedia/mpm/10.png';
import sm_mpm_11 from '../assets/portfolio/socialmedia/mpm/11.png';
import sm_mpm_12 from '../assets/portfolio/socialmedia/mpm/12.png';
import sm_mpm_13 from '../assets/portfolio/socialmedia/mpm/13.png';
import sm_mpm_14 from '../assets/portfolio/socialmedia/mpm/14.png';

// Opic Website
import web_opic_1 from "../assets/projects/opic-list/contact.png";
import web_opic_2 from "../assets/projects/opic-list/contact1.png";
import web_opic_3 from "../assets/projects/opic-list/contact2.png";
import web_opic_4 from "../assets/projects/opic-list/home.png";
import web_opic_5 from "../assets/projects/opic-list/location.png";
import web_opic_6 from "../assets/projects/opic-list/services.png";
import web_opic_home from "../assets/projects/opicHomePage.png";
import web_opic_banner from "../assets/projects/opicBanner.png";

// MPM Website
import web_mpm_home from "../assets/projects/mpm/homeMPM.png";
import web_mpm_1 from "../assets/projects/mpm/products.png";
import web_mpm_2 from "../assets/projects/mpm/protection.png";
import web_mpm_3 from "../assets/projects/mpm/test.png";

// OPIC PRESENTATION 
import present_opic from '../assets/portfolio/presentation/opic-presentation.png'

// Personal branding
import img_essebar1 from '../assets/portfolio/personal-branding/1-photo-essebar1.png';
import video_essebar1 from '../assets/portfolio/personal-branding/1-video-essebar1-sized.mp4';
import video_essebar2 from '../assets/portfolio/personal-branding/1-vid-essebar-02-sized.mp4';
import video_essebar3 from '../assets/portfolio/personal-branding/1-vid-essebar-03-sized.mp4';

// Audis - AudioVisuel
// import video_audis_1 from '../assets/portfolio/audiovisuel/1-vid-audis-01.mp4';
import video_audis_1 from '../assets/portfolio/audiovisuel/1-vid-audis-01-sized.mp4';
import img_audis_1 from '../assets/portfolio/audiovisuel/1-img-audis-01.png';

// Evomarket - AudioVisuel
import video_evo_1 from '../assets/portfolio/audiovisuel/1-vid-evo-01.mp4';
import img_evo_1 from '../assets/portfolio/audiovisuel/1-img-evo-01.png';
// ESGT - Event
import video_esgt_1 from '../assets/portfolio/events/1-vid-esgt-01-hd.mp4';
import img_esgt_1 from '../assets/portfolio/events/1-img-esgt-01.png';



const services = [
  {
    id: 1,
    name: "Conseil & stratégie",
    image: marketing,
    rol: "Suivi, conseil et élaboration de stratégie",
    img: stratégie,
    description:
      "Suivi, conseil et élaboration de stratégie de communication et de marketing",
    lien: "/conseil-strategie",
  },
  {
    id: 2,
    name: "Développement web",
    rol: "Création de votre site web",
    image: web,
    img: webDev,
    description:
      "Création de sites internet, élaboration et exécution de stratégies digitales",
    lien: "/digital-web",
  },

  {
    id: 3,
    name: "Digital Marketing",
    rol: "Gestion de vos réseaux sociaux et création de votre identité visuelle",
    img: reseaux_sociaux,
    image: video,
    description:
      "Création d'identités visuelles et tout support de communication",
    lien: "/graphique-design",
  },
  {
    id: 4,
    name: "Événementiel",
    rol: "Tournage d'événements, de campagnes publicitaires",
    img: events,
    image: video,
    description:
      "Création d'identités visuelles et tout support de communication",
    lien: "/graphique-design",
  },
];

const partners = [
  {
    name: "Conquete",
    image: conquete,
    message: "",
  },
  {
    name: "Maison Paramed",
    image: mpm,
    message: "",
  },
  {
    name: "Audis",
    image: audis,
    message: "",
  },
  {
    name: "Opic",
    image: opic,
    message: "",
  },
  {
    name: "ESGT",
    image: esgt,
    message: "",
  },
  {
    name: "Chronovision",
    image: chronovision,
    message: "",
  },
  {
    name: "Essebar",
    image: essaber,
    message: "",
  },
  {
    name: "Electrosky",
    image: electrosky,
    message: "",
  },
];
const projects = [
  {
    id: 1,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/webdev/1",
  },
  {
    id: 2,
    name: "Maison Paramed - Site web Static",
    image: mpmProject,
    category: "Developpement Web",
    lien: "/webdev/2",
  },
  {
    id: 3,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/webdev/1",
  },
  {
    id: 2,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
  {
    id: 3,
    name: "Maison Paramed - Site web Static",
    image: mpmProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
  {
    id: 2,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },

  // {
  //   id: 4,
  //   name: "Opic - Site web Static",
  //   image: opicProject,
  //   category: "Developpement Web",
  //   lien: "/opic-details",
  // },
  // {
  //   id: 5,
  //   name: "Maison Paramed - Site web Static",
  //   image: mpmProject,
  //   category: "Developpement Web",
  //   lien: "/opic-details",
  // },
];
const testimonials = [
  {
    id: 1,
    company: "Opic Maroc",
    name: "Issam Rahoui",
    post: "Directeur général",
    desc: "Je ne peux que recommander ce service. L'équipe a su répondre à mes besoins de manière rapide et efficace. Leur expertise m'a permis de gagner du temps et d'atteindre mes objectifs bien plus facilement que je ne l'aurais imaginé. Un service professionnel et humain à la fois !",
    image: opic,
  },
  {
    id: 4,
    company: "Maison Paramed",
    name: "Youssef Qd",
    post: "Directeur général ",
    desc: "Le service a été irréprochable du début à la fin. Ils ont pris le temps de comprendre mes besoins, ont proposé des solutions sur mesure et m'ont accompagné tout au long du projet. Je suis ravi du résultat et je n'hésiterai pas à faire de nouveau appel à eux.",
    image: mpm,
  },
  {
    id: 3,
    company: "Audis",
    name: "Fatima Zahra",
    post: "Chef Departement Communication",
    desc: "Je ne peux que recommander ce service. Je suis très satisfaite du service. L'équipe a été à l'écoute, disponible et toujours de bon conseil. Ils ont su anticiper mes attentes et m'ont guidé tout au long du processus. Un service client exemplaire, je recommande sans hésitationmander ce service.",
    image: audis,
  },
  {
    id: 4,
    company: "Essebar Architects",
    name: "Soufiane Essebar",
    post: "Architecte",
    desc: "Une prestation de qualité à tous les niveaux ! Le service a été personnalisé, rapide et extrêmement professionnel. J'ai particulièrement apprécié la réactivité de l'équipe face à mes demandes. C'est rare de trouver un service aussi complet.",
    image: essaber,
  },
  {
    id: 5,
    company: "Chronovision",
    name: "Brahim",
    post: "Responsable Magasin",
    desc: "Le service fourni a surpassé mes attentes. Tout a été parfaitement orchestré, du premier contact à la finalisation du projet. Ils m'ont tenu informé à chaque étape et ont veillé à ce que tout soit conforme à mes attentes. Je suis vraiment impressionné par leur professionnalisme.",
    image: chronovision,

  },
];

const portfolio_assets = [
  {
    id: 1,
    name: "Opic - Site web",
    category: "Developpement web",
    image: portfolio_opic,
    listImages: [web_opic_1, web_opic_2, web_opic_3, web_opic_4, web_opic_5, web_opic_6,],
    home: web_opic_home,
    bg: web_opic_banner,
    website: "https://www.opicmaroc.com",
    features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web de docteur AL TUBOR HADI Atika, dermatologue à Alger. L’objectif du site est de créer une plate-forme, qui servira à présenter les différentes prestations que propose le cabinet de Dr AL TUBOR, mais aussi, d’une plate-forme de prise de RDV en ligne."
  },
  {
    id: 2,
    name: "Maison Paramed - Site web",
    category: "Developpement web",
    image: web_mpm_home,
    listImages: [web_mpm_1, web_mpm_2, web_mpm_3],
    home: web_mpm_home,
    bg: web_mpm_home,
    website: "maison-paramed-mar.netlify.app/",
    features: ["Site Static", "Design adaptatif (responsive design)", "Interfaces modernes", "Interactions fluides", "Expérience utilisateur optimale", "Accessibilité optimale"],
    description: "Nous avons eu le plaisir de concevoir et de mettre en ligne le nouveau site web de docteur AL TUBOR HADI Atika, dermatologue à Alger. L’objectif du site est de créer une plate-forme, qui servira à présenter les différentes prestations que propose le cabinet de Dr AL TUBOR, mais aussi, d’une plate-forme de prise de RDV en ligne."

  },

  {
    id: 3,
    name: "Opic - Social Media",
    category: "Social Media",
    image: portfolio_opic,
    carousel: [sm_opic_1, sm_opic_2, sm_opic_3, sm_opic_4, sm_opic_5, sm_opic_6, sm_opic_7, sm_opic_8, sm_opic_9]
  },
  {
    id: 4,
    name: "Audis - Social Media",
    category: "Social Media",
    image: sm_audis_7,
    carousel: [sm_audis_1, sm_audis_2, sm_audis_3, sm_audis_4, sm_audis_5, sm_audis_6, sm_audis_7, sm_audis_8, sm_audis_9, sm_audis_10, sm_audis_11, sm_audis_12]
  },
  {
    id: 5,
    name: "Chronovision Casa - Social Media",
    category: "Social Media",
    image: sm_chronocasa_1,
    carousel: [sm_chronocasa_1, sm_chronocasa_2, sm_chronocasa_3, sm_chronocasa_4, sm_chronocasa_5, sm_chronocasa_6, sm_chronocasa_7, sm_chronocasa_8, sm_chronocasa_9,]
  },
  {
    id: 6,
    name: "Opic - Présentation",
    category: "Présentation",
    image: present_opic,
    carousel: [sm_chronocasa_1, sm_chronocasa_2, sm_chronocasa_3, sm_chronocasa_4, sm_chronocasa_5, sm_chronocasa_6, sm_chronocasa_7, sm_chronocasa_8, sm_chronocasa_9]
  },
  {
    id: 7,
    name: "Essebar Architects",
    category: "Personal Branding",
    image: img_essebar1,
    carousel: [

      {
        videoUrl: video_essebar1,
        aspect: "portrait"
      },
      {
        videoUrl: video_essebar2,
        aspect: "portrait"
      },
      {
        videoUrl: video_essebar3,
        aspect: "portrait"
      },
    ]
  },
  {
    id: 8,
    name: "Maison Paramed - Social Media",
    category: "Social Media",
    image: sm_mpm_4,
    carousel: [sm_mpm_1, sm_mpm_2, sm_mpm_3, sm_mpm_4, sm_mpm_5, sm_mpm_6, sm_mpm_7, sm_mpm_8, sm_mpm_9, sm_mpm_10, sm_mpm_11, sm_mpm_12, sm_mpm_13, sm_mpm_14]
  },
  {
    id: 9,
    name: "Audis - Promotional Videos",
    category: "Audiovisuel",
    image: img_audis_1,
    carousel: [
      {
        videoUrl: video_audis_1,
        aspect: "portrait"
      },

    ]

  },
  {
    id: 10,
    name: "EvoMarket - Trend Videos",
    category: "Audiovisuel",
    image: img_evo_1,
    carousel: [
      {
        videoUrl: video_evo_1,
        aspect: "portrait"
      },

    ]

  },
  {
    id: 11,
    name: "ESGT - Graduation Event",
    category: "Event",
    image: img_esgt_1,
    carousel: [
      {
        videoUrl: video_esgt_1,
        aspect: "portrait"
      },

    ]

  },

]
export { services, partners, projects, testimonials, portfolio_assets };
