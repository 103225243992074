import React from 'react'

export const TopHead = ({bgImage,Maintitle,SubTitle}) => {
  return (
    <div
        // style={{
        //   backgroundImage: `url(${bgImage})`,
        // }}
        className="bg-gradient-to-r from-black to-gray-900 w-full h-[400px] mt-20 flex items-center   z-0 relative  justify-center  bg-no-repeat bg-cover bg-center"
      >
        <div className=" absolute z-10 w-full h-full"></div>
        <div className="  z-20 text-center">
          <h1 className="text-[20px] md:text-[26px] lg:text-[40px] font-semibold text-customGold">
            {Maintitle}
          </h1>
          <h3 className={` w-full lg:w-[50%] text-center m-auto text-[15px] md:text-[17px] lg:text-[20px] font-medium ${bgImage ? 'underline' : ''} cursor-pointer text-fuchsia-100`}>
           {SubTitle}
          </h3>
        </div>
      </div>
  )
}
