import React from "react";
import { testimonials } from "../utils/data";
import "swiper/css";
import "swiper/css/pagination";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaQuoteLeft } from "react-icons/fa";

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };
  return (
    <div className=" relative">
         <FaQuoteLeft className=" absolute top-3 text-customBlue/35 left-5  text-[40px]"/>
        <Slider {...settings}>
      {testimonials.map((testimonial, index) => (
        <div className=" flex px-10 flex-col items-center justify-center  gap-2 text-white">
           
            <h1 className=" w-fit m-auto my-4 text-customGold text-[30px] font-medium">{testimonial.company}</h1>
            <p className=" leading-[26px] text-[16px] md:text-[18px] text-center lg:text-clip">{testimonial.desc}</p>
            <div className=" flex items-start gap-4 justify-center  mt-4">
                <img className=" w-[60px] h-[60px]" src={testimonial.image} alt="" />
                <div className=" flex flex-col gap-1">
                        <h2 className=" text-white capitalize text-[17px]">{testimonial.name}</h2>
                        <h3 className=" text-[15px] capitalize text-customBlue">{testimonial.post}</h3>
                </div>
            </div>
        </div>
      ))}
    </Slider>
    </div>
  );
};

export default Testimonials;
