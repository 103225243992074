import React from "react";
import { TbSquareRoundedChevronRightFilled } from "react-icons/tb";

function CardPortfolio({ index, asset }) {
  return (
    <div
      key={index}
      className="rounded-sm bg-opacity-95 bg-white pt-6 pb-20 px-4 relative xs: "
      data-aos="zoom-in-up"
    >
      <span className=" uppercase w-full block  text-[12px]  bg-custom-primary text-white text-center ">
        {asset.level}
      </span>
      <a
        href={`/${
          asset.category === "Social Media"
            ? "socialmedia"
            : asset.category === "Audiovisuel"
            ? "audiovisuel"
            : asset.category === "Developpement web"
            ? "webdev"
            : asset.category === "Présentation"
            ? "presentation"
            : asset.category === "Personal Branding"
            ? "personalbranding"
            : asset.category === "Event"
            ? "event"
            : ""
        }/${asset.id}`}
        className=" group "
      >
        <div className=" relative h-[200px] w-full overflow-hidden shadow-md rounded">
          <img
            src={asset.image}
            // className=" group-hover:scale-105 transition-all duration-200 absolute w-full h-full"
            className="object-cover"
            alt=""
          />
        </div>
        <h1 className="text-customBlue text-[23px] font-bold w-full mt-2 underline">
          {asset.name}
        </h1>
      </a>
      <span className=" capitalize block">{asset.category}</span>
      <p className=" text-[15px] text-gray-500 text-center mt-3">
        {asset.desc}
      </p>
      {/* ----------- Button */}
      <div className="absolute bottom-4 mt-auto flex flex-row justify-center items-center bg-customGold py-2 w-11/12">
        <a
          href={`/${
            asset.category === "Social Media"
              ? "socialmedia"
              : asset.category === "Audiovisuel"
              ? "audiovisuel"
              : asset.category === "Developpement web"
              ? "webdev"
              : asset.category === "Présentation"
              ? "presentation"
              : asset.category === "Personal Branding"
              ? "personalbranding"
              : asset.category === "Event"
              ? "event"
              : ""
          }/${asset.id}`}
          className="flex flex-row justify-center items-center"
          alt="Découvrir"
        >
          Découvrir{" "}
          <TbSquareRoundedChevronRightFilled className="ml-2 text-[24px]" />
        </a>
      </div>
    </div>
  );
}

export default CardPortfolio;
