import React, { useState } from "react";

import { portfolio_assets } from "../utils/data";
import HeaderPorfolio from "../components/HeaderPorfolio";
import CardPortfolio from "../components/CardPortfolio";

function Portfolio() {
  const [showAssets, setshowAssets] = useState("touts");
  const [data, setData] = useState(portfolio_assets);
  const [webdev_array] = useState(
    portfolio_assets.filter((state) => state.category === "Developpement web")
  );
  const [socialmedia_array] = useState(
    portfolio_assets.filter((state) => state.category === "Social Media")
  );
  const [audiovisuel_array] = useState(
    portfolio_assets.filter((state) => state.category === "Audiovisuel")
  );
  const [presentation_array] = useState(
    portfolio_assets.filter((state) => state.category === "Présentation")
  );
  const [personal_branding_array] = useState(
    portfolio_assets.filter((state) => state.category === "Personal Branding")
  );
  const [event_array] = useState(
    portfolio_assets.filter((state) => state.category === "Event")
  );
  // Filter assets based on the selected category
  const filteredAssets = (cat) => {
    if (cat === "touts") {
      setData(portfolio_assets);
      return;
    }
    const newAsset = portfolio_assets.filter((state) =>
      state.category.includes(cat)
    );
    setData(newAsset);
  };
  return (
    <div className="py-10">
      {/* ---------- TITLE PAGE  */}
      <HeaderPorfolio />
      {/* ------------ unnumbered list -------- */}
      <div className="mt-10 px-4 sm:px-8 md:px-16 lg:px-12 xl:px-26">
        <ul className=" grid grid-cols-2  capitalize sm:flex items-center gap-4 sm:h-[50px] ">
          <li
            onClick={() => {
              setshowAssets("touts");
              filteredAssets("touts");
            }}
            className={`whitespace-nowrap cursor-pointer h-full font-bold flex items-center justify-center before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${
              showAssets === "touts"
                ? " before:block text-customBlue"
                : "before:hidden text-customGold"
            }`}
          >
            Tout ({portfolio_assets.length})
          </li>
          <li
            onClick={() => {
              setshowAssets("Developpement web");
              filteredAssets("Developpement web");
            }}
            className={` whitespace-nowrap sm:left-0 cursor-pointer h-full  font-bold flex items-center justify-center before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${
              showAssets === "Developpement web"
                ? " before:block text-customBlue"
                : "before:hidden text-customGold"
            }`}
          >
            Développement web ({webdev_array.length})
          </li>

          <li
            onClick={() => {
              setshowAssets("Social Media");
              filteredAssets("Social Media");
            }}
            className={`whitespace-nowrap cursor-pointer h-full font-bold flex items-center justify-center before:-bottom-[1px] sm:before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${
              showAssets === "Social Media"
                ? " before:block text-customBlue"
                : "before:hidden text-customGold"
            }`}
          >
            Réseaux sociaux ({socialmedia_array.length})
          </li>
          <li
            onClick={() => {
              setshowAssets("Audiovisuel");
              filteredAssets("Audiovisuel");
            }}
            className={`whitespace-nowrap cursor-pointer h-full font-bold flex items-center justify-center before:-bottom-[1px] sm:before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${
              showAssets === "Audiovisuel"
                ? " before:block text-customBlue"
                : "before:hidden text-customGold"
            }`}
          >
            Audiovisuel ({audiovisuel_array.length})
          </li>
          <li
            onClick={() => {
              setshowAssets("Présentation");
              filteredAssets("Présentation");
            }}
            className={`whitespace-nowrap cursor-pointer h-full font-bold flex items-center justify-center before:-bottom-[1px] sm:before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${
              showAssets === "Présentation"
                ? " before:block text-customBlue"
                : "before:hidden text-customGold"
            }`}
          >
            Présentation ({presentation_array.length})
          </li>
          <li
            onClick={() => {
              setshowAssets("Personal Branding");
              filteredAssets("Personal Branding");
            }}
            className={`whitespace-nowrap cursor-pointer h-full font-bold flex items-center justify-center before:-bottom-[1px] sm:before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${
              showAssets === "Personal Branding"
                ? " before:block text-customBlue"
                : "before:hidden text-customGold"
            }`}
          >
            Personal branding ({personal_branding_array.length})
          </li>
          <li
            onClick={() => {
              setshowAssets("Event");
              filteredAssets("Event");
            }}
            className={`whitespace-nowrap cursor-pointer h-full font-bold flex items-center justify-center before:-bottom-[1px] sm:before:top-0 relative before:absolute before:content-[''] before:h-[1.4px] before:w-full before:bg-custom-primary ${
              showAssets === "Event"
                ? " before:block text-customBlue"
                : "before:hidden text-customGold"
            }`}
          >
            Événementiel ({event_array.length})
          </li>
        </ul>
        <div className=" grid xs:grid-cols-1 md:grid-cols-2  lg:grid-cols-4 xl:grid-cols-4 gap-4 mt-6">
          {data.map((asset, index) => (
            // ---------------------------------
            // ------------ CARD ITEM ----------
            // ---------------------------------
            <CardPortfolio index={index} asset={asset} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
