import React from "react";
// Import the styles

// PDF file path (local or URL)
// Update with your actual file path or URL

function PDFviewer() {
  return (
    <div className="py-20 min-h-screen">
      <iframe
        src={`${process.env.PUBLIC_URL}/opic_presentation_sized.pdf`}
        type="application/pdf"
        width="100%"
        style={{minHeight: '1000px'}}
        title="Embedded PDF Viewer"
      />
    </div>
  );
}

export default PDFviewer;
