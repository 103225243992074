import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TopHead } from "../components/TopHead";
import { portfolio_assets } from "../utils/data";
import Aos from "aos";
function SocialMediaPorfolio() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const { id } = useParams();
  const [item] = React.useState(
    portfolio_assets.filter((item) => item.id == id)[0]
  );

  return (
    <div>
      <TopHead
        bgImage={item.image}
        Maintitle={item.name}
        SubTitle={item.category}
      />
      {/* -------- Gallery */}
      <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full m-auto py-14 px-14">
        {item.carousel.map((e, index) => (
          <img src={e} alt="" data-aos="fade-up-right" />
        ))}
      </div>
    </div>
  );
}

export default SocialMediaPorfolio;
