
import { DefaultPlayer as Video } from "react-html5video";
import React from "react";

function VideoPlayer({video}) {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center w-full md:w-[80%] lg:w-full ">
      <Video
        autoPlay={false}
        loop
        controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
        poster=""
        onCanPlayThrough={() => {
          // Do stuff
        }}
        className=" w-full md:w-3/4 lg:w-full max-w-[650px] object-contain "
      >
        <source src={video} type="video/mp4" />
        
      </Video>

      
    </div>
  );
}

export default VideoPlayer;





// import React, { useRef, useState } from "react";
// import {
//   FaPlay,
//   FaPause,
//   FaVolumeUp,
//   FaVolumeMute,
//   FaExpand,
//   FaCompress,

//   FaArrowLeft,
//   FaArrowRight,
// } from "react-icons/fa";

// const VideoPlayer = ({ videoList }) => {
//   const videoRef = useRef(null);
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [isMuted, setIsMuted] = useState(false);
//   const [volume, setVolume] = useState(1);
//   const [progress, setProgress] = useState(0);
//   const [isFullscreen, setIsFullscreen] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);

//   // Toggle Play/Pause
//   const togglePlayPause = () => {
//     if (isPlaying) {
//       videoRef.current.pause();
//     } else {
//       videoRef.current.play();
//     }
//     setIsPlaying(!isPlaying);
//   };

//   // Toggle Mute/Unmute
//   const toggleMute = () => {
//     videoRef.current.muted = !isMuted;
//     setIsMuted(!isMuted);
//   };

//   // Handle Volume Change
//   const handleVolumeChange = (e) => {
//     const newVolume = e.target.value;
//     videoRef.current.volume = newVolume;
//     setVolume(newVolume);
//     setIsMuted(newVolume === "0");
//   };

//   // Handle Progress Update
//   const handleProgress = () => {
//     const progressValue =
//       (videoRef.current.currentTime / videoRef.current.duration) * 100;
//     setProgress(progressValue);
//   };

//   // Handle Seek (Scrubbing)
//   const handleSeek = (e) => {
//     const seekTime = (e.target.value / 100) * videoRef.current.duration;
//     videoRef.current.currentTime = seekTime;
//     setProgress(e.target.value);
//   };

//   // Fullscreen toggle
//   const handleFullScreen = () => {
//     if (!isFullscreen) {
//       videoRef.current.requestFullscreen();
//     } else {
//       document.exitFullscreen();
//     }
//     setIsFullscreen(!isFullscreen);
//   };

//   // Handle Video Load
//   const handleCanPlay = () => {
//     setIsLoading(false); // Video is ready to play, remove the loading spinner
//   };

//   // Handle video error
//   const handleError = () => {
//     setIsLoading(false); // Hide loading spinner if there's an error
//   };

//   // Handle Previous Video
//   const handlePrevious = () => {
//     if (currentVideoIndex > 0) {
//       setCurrentVideoIndex(currentVideoIndex - 1);
//       resetPlayer();
//     }
//   };

//   // Handle Next Video
//   const handleNext = () => {
//     if (currentVideoIndex < videoList.length - 1) {
//       setCurrentVideoIndex(currentVideoIndex + 1);
//       resetPlayer();
//     }
//   };

//   // Reset player state when switching videos
//   const resetPlayer = () => {
//     setIsPlaying(false);
//     setProgress(0);
//     setIsLoading(true);
//     if (videoRef.current) {
//       videoRef.current.pause();
//       videoRef.current.currentTime = 0;
//     }
//   };

//   return (
//     <div className="flex justify-center items-center bg-gray-900 overflow-hidden">
//       <div
//         className={`flex flex-col justify-center w-full max-w-4xl aspect-video ${
//           videoList[currentVideoIndex].aspect === "landscape"
//             ? "h-[400px] lg:h-[750px]"
//             : "h-[750px]"
//         }`}
//       >
//         {/* Video element */}
//         <video
//           ref={videoRef}
//           onTimeUpdate={handleProgress}
//           onCanPlay={handleCanPlay} // Use this to hide loading spinner
//           onError={handleError}
//           className={`w-full  object-contain rounded-lg ${
//             videoList[currentVideoIndex].aspect === "landscape"
//               ? "h-[500px]"
//               : "h-[600px]"
//           }`}
//           src={videoList[currentVideoIndex].videoUrl}
//           type="video/mp4"
//         ></video>

//         {/* Loading Spinner */}
//         {/* {isLoading && (
//           <div className="absolute inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50">
//             <FaSpinner className="text-white text-4xl animate-spin" />
//           </div>
//         )} */}

//         {/* Controls */}
//         <div className="w-full bg-gray-900 bg-opacity-75 p-3 rounded-b-lg">
//           <div className="flex justify-between items-center">
//             {/* Play/Pause Button */}
//             <button onClick={togglePlayPause} className="text-white">
//               {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
//             </button>

//             {/* Progress Bar */}
//             <input
//               type="range"
//               value={progress}
//               onChange={handleSeek}
//               className="w-3/4 h-2 bg-gray-400 rounded-lg cursor-pointer mx-4"
//             />

//             {/* Volume Control */}
//             <div className="flex items-center space-x-2">
//               <button onClick={toggleMute} className="text-white">
//                 {isMuted ? (
//                   <FaVolumeMute size={20} />
//                 ) : (
//                   <FaVolumeUp size={20} />
//                 )}
//               </button>
//               <input
//                 type="range"
//                 min="0"
//                 max="1"
//                 step="0.1"
//                 value={volume}
//                 onChange={handleVolumeChange}
//                 className="w-20 h-2 bg-gray-400 rounded-lg cursor-pointer"
//               />
//             </div>

//             {/* Fullscreen Button */}
//             <button onClick={handleFullScreen} className="text-white">
//               {isFullscreen ? <FaCompress size={20} /> : <FaExpand size={20} />}
//             </button>
//           </div>

//           {/* Previous and Next Buttons */}
//           <div className="flex justify-between mt-4">
//             <button
//               onClick={handlePrevious}
//               disabled={currentVideoIndex === 0}
//               className={`text-white bg-customGold p-4 rounded-full ${
//                 currentVideoIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
//               }`}
//             >
//               <FaArrowLeft className="text-customBlue" size={20} />
//             </button>
//             <button
//               onClick={handleNext}
//               disabled={currentVideoIndex === videoList.length - 1}
//               className={`text-white bg-customGold p-4 rounded-full ${
//                 currentVideoIndex === videoList.length - 1
//                   ? "opacity-50 cursor-not-allowed"
//                   : ""
//               }`}
//             >
//               <FaArrowRight className="text-customBlue"  size={20} />
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoPlayer;
