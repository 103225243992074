import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { partners } from "../utils/data";

const PartnersComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div className=" partners overflow-hidden py-10 px-4 sm:px-10 md:px-16 xl:px-36 bg-[#ffffff05] mb-10">
      <h1
        data-aos="fade-right"
        className={` mb-8  w-fit before:content-[''] before:absolute before:z-[-1] z-10 before:top-5  relative before:w-[60%] before:h-2 before:bg-customBlue  text-customGold uppercase text-[20px] md:text-[22px] lg:text-[26px] font-bold`}
      >
        ILS NOUS FONT CONFIANCE
      </h1>
      <Slider {...settings}>
        {partners.map((item, index) => (
          <div key={index} className=" outline-none h-fit w-fit">
            <img src={item.image} className=" lg:h-[150px] lg:w-[150px]" alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnersComponent;
