import React from "react";
import { TopHead } from "../components/TopHead";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { RiMapPin2Fill } from "react-icons/ri";
import MapComponent from "../components/MapComponent";

const Contact = () => {
  return (
    <div>
      <TopHead Maintitle="NOS ÉQUIPES À VOTRE SERVICE" />
      <div className=" px-4 sm:px-10 md:px-16 xl:px-36  my-20 flex flex-col lg:flex-row items-start gap-[80px]">
        <div className=" w-full lg:w-[50%]">
          <h1
            data-aos="fade-right"
            className={`  w-fit before:content-[''] before:absolute before:z-[-1] z-10 before:top-5  relative before:w-[60%] before:h-2 before:bg-customBlue  text-customGold uppercase text-[20px] md:text-[22px] lg:text-[26px] font-bold`}
          >
            HEY! 👋
          </h1>
          <p className=" text-[16px] text-gray-300">
            Nous mettons à votre disposition plusieurs méthodes qui vous
            permettent de nous contacter. Pour tout renseignement ou demande de
            devis, utilisez le formulaire do contact.{" "}
          </p>
          <p className=" text-[16px] text-gray-300 mt-6 mb-4">
            Vous pouvez à tout moment nous contacter via les coordonnées
            suivantes :
          </p>
          <div className=" text-gray-300">
            <div className=" flex flex-col gap-1 mb-6">
              <h1 className=" text-white flex items-center gap-3 mb-2 text-[23px] font-medium ">
                <IoCall /> Nous appeler
              </h1>
              <span>+212 5 30 31 50 11</span>
              <span>+212 6 24 45 88 47</span>
            </div>
            <div className=" flex flex-col gap-1 mb-6">
              <h1 className="text-white  flex items-center gap-3 mb-2 text-[23px] font-medium ">
                <MdEmail /> Nous envoyer un email
              </h1>
              <span>contact@evomarketagency.com</span>
            </div>
            <div className="  flex flex-col gap-1 mb-6">
              <h1 className="text-white flex items-center gap-3 mb-2 text-[23px] font-medium ">
                <RiMapPin2Fill /> Nous rendre visite
              </h1>
              <span>10 Rue Ghandi, Technopark, Office R 401, Rabat</span>
            </div>
          </div>
        </div>
        <form className=" text-white  w-full lg:w-[50%] flex flex-col gap-5 py-6 px-4 border border-gray-400/45">
          <div className=" flex items-center flex-col md:flex-row gap-2 justify-between">
            <div className=" flex flex-col gap-2 w-full">
              <label htmlFor="">
                Nom <span className=" text-red-500">*</span>
              </label>
              <input type="text" required className=" focus:border-customGold/45  outline-none border rounded-sm border-gray-400/45 py-2 pl-4 w-full bg-transparent"/>
            </div>
            <div className=" flex flex-col gap-2 w-full">
              <label htmlFor="">Prenom </label>
              <input type="text" className=" focus:border-customGold/45 outline-none border border-gray-400/45 py-2 pl-4 w-full bg-transparent"/>
            </div>
          </div>
          <div className=" flex flex-col gap-2 w-full">
            <label htmlFor="">
              Email <span className=" text-red-500">*</span>
            </label>
            <input type="email" required className=" focus:border-customGold/45 outline-none border border-gray-400/45 py-2 pl-4 w-full bg-transparent"/>
          </div>
          <div className=" flex flex-col gap-2 w-full">
            <label htmlFor="">
              Téléphone             
            </label>
            <input type="text" className=" focus:border-customGold/45  outline-none border border-gray-400/45 py-2 pl-4 w-full bg-transparent"/>
          </div>
          <div className=" flex flex-col gap-2 w-full">
            <label htmlFor="">
              Raison sociale            
            </label>
            <input type="text" className=" focus:border-customGold/45 outline-none border border-gray-400/45 py-2 pl-4 w-full bg-transparent"/>
          </div>
          <div className=" flex flex-col gap-2 w-full">
            <label htmlFor="">
              Message
              <span className=" text-red-500">*</span>
            </label>
            <textarea rows={8} required type="text"  className=" focus:border-customGold/45  outline-none border border-gray-400/45 py-2 pl-4 w-full bg-transparent"/>
          </div>
          <a href="/" className="uppercase w-fit px-4 py-1 bg-customBlue text-white m-auto my-2">soumettre</a>
        </form>
      </div>
     <div className="  z-0">
     <MapComponent/>
     </div>
    </div>
  );
};

export default Contact;
