import React from "react";
import VideoPlayer from "../components/VideoPlayer";
import { useParams } from "react-router-dom";
import HeaderPorfolio from "../components/HeaderPorfolio";
import { TopHead } from "../components/TopHead";
import { portfolio_assets } from "../utils/data";
import { TopHeadSized } from "../components/TopHeadSized";

function PersonalBranding() {
  const { id } = useParams();
  const [item] = React.useState(
    portfolio_assets.filter((item) => item.id == id)[0]
  );
  return (
    <>
      <TopHeadSized
        bgImage={item.image}
        Maintitle={item.name}
        SubTitle={item.category}
        size={200}
      />
      <VideoPlayer video={item.carousel[0].videoUrl} />;
    </>
  );
}

export default PersonalBranding;
