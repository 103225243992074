import React, { useEffect } from 'react';

const CursorFollow = () => {
  useEffect(() => {
    // Create the point element
    const cursor = document.createElement('div');
    cursor.style.position = 'fixed';
    cursor.style.width = '12px';
    cursor.style.height = '12px';
    cursor.style.borderRadius = '50%';
    cursor.style.backgroundColor = '#FFD700'; // You can change the color
    cursor.style.pointerEvents = 'none';
    cursor.style.zIndex = '1000'; // Keep it on top of other content
    cursor.style.transform = 'translate(-50%, -50%)'; // Center the point on the cursor
    document.body.appendChild(cursor); // Append the point to the body

    // Update the point position on mouse move
    const moveCursor = (e) => {
      cursor.style.left = `${e.clientX}px`;
      cursor.style.top = `${e.clientY}px`;
    };

    window.addEventListener('mousemove', moveCursor);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('mousemove', moveCursor);
      document.body.removeChild(cursor);
    };
  }, []);

  return null; // No need to render anything within React itself
};

export default CursorFollow;
