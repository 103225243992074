import React from 'react'
import PDFviewer from '../components/PDFviewer'

function PresentationPortfolio() {
  return (
    <div className="py-20">
        <PDFviewer/>
    </div>
  )
}

export default PresentationPortfolio