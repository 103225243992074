import React from "react";
import { TopHead } from "../components/TopHead";
import { services } from "../utils/data";
const Services = () => {
  return (
    <div>
      <TopHead
        image={null}
        Maintitle="UNE AGENCE 360"
        SubTitle="Evomarket Studio de Création met à votre disposition un large catalogue de services pour vous permettre une communication efficace"
      />
      <div className=" my-[100px] grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3  lg:gap-3 px-4 sm:px-10 md:px-16 xl:px-36"> 
      {services.map((serv, index) => (
        <div key={index} className="  relative rounded-[1px] group  cursor-pointer overflow-hidden w-full h-[300px]">
                 <img src={serv.img} alt=""  className=" w-full h-full object-cover absolute z-0"/>
                 <div className=" flex justify-center items-center absolute h-[30%] group-hover:h-full transition-all duration-300  w-full bottom-0 bg-customBlue/85 z-10">
                    <div className=" group-hover:flex hidden  items-center gap-2 flex-col">
                        <h1 className=" text-white font-medium text-[20px]">{serv.name}</h1>
                        <h3 className=" text-center text-customGold">{serv.rol}</h3>
                        <a href="/" className="text-customGold underline">Discover</a>                        
                    </div>
                    <h1 className=" block group-hover:hidden text-white font-medium text-[23px]">{serv.name}</h1>
                 </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default Services;
